import React, { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "antd";
import loader from "../../methods/loader";
import { useHistory } from "react-router-dom";
import "./style.scss";
import "react-datepicker/dist/react-datepicker.css";
import { CiAlignRight } from "react-icons/ci";
import { MdDataObject } from "react-icons/md";
import ModalOKR from "./ModalOKR";
import { useDispatch, useSelector } from "react-redux";
import AlignmentOKR from "./AlignmentOKR";
import { LuCircleDotDashed } from "react-icons/lu";
import { MdOutlineSignalCellularAlt } from "react-icons/md";
import { sessions } from "../../actions/session";
import Select from "react-select";
import { Menu } from "@headlessui/react";
import { CiFilter } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import ChildModalOKR from "./ChildModalOKR";
import methodModel from "../../methods/methods";
import InfiniteScroll from 'react-infinite-scroll-component';

const OKRindex = ({ layout = true, ownerID, sessionId, getcounts }) => {


  const { id } = useParams();
  const [LoadingState, setLoadingState] = useState(false);
  const getsessionId = localStorage.getItem("sessionID");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const sessionss = useSelector((state) => state.session);
  const SessionFilter = sessionss?.data
    ? { label: sessionss?.data, value: id }
    : "";
  const [data, setdata] = useState([]);
  const [filter, setfilter] = useState({
    page: 1,
    count: 12,
    search: "",
    ownerId: "",
    sessionId: sessionId,
  });
  const [SessionIdFilter, setSessionIdFilter] = useState(SessionFilter);
  const [childKeyResult, setchildKeyResult] = useState("");
  const [loading, setloading] = useState(false);
  const [total, settotal] = useState(0);
  const [child, setchild] = useState(0);
  const [detail, setdetail] = useState();
  const navigate = useHistory();
  // Create OKRs
  const [session, setsessions] = useState();
  const [owners, setowners] = useState([]);
  const [multiOptions, setmultiOptions] = useState([]);
  const [form, setform] = useState({ session: "", title: "", ownerIds: [], contributors: [], whyNow: '' });
 
  const [keyResult, setkeyResult] = useState({
    title: "",
    unit: "",
    targetNumber: "",
    initialNumber: "",
    description: "",
    ownerIds: [],
    deadline: "no custom deadline",
    softDeadline: "",
    hardDeadline: "",
    session: null,
  });
  const [editor, seteditor] = useState({ keyResult: "", noteKeyResult: "" });
  const [updateKeyResult, setupdateKeyResult] = useState({
    newValue: "",
    confidenceLevel: "high",
    note: "",
    dateOfUpdate: new Date(),
    okrId: "",
    goal: "",
    currentValue: "",
    keyResultId: "",
  });
  const [chlildObjective, setchlidObjective] = useState();
  const [keyResultData, setkeyResultData] = useState();
  const [parentKeyResult, setparentKeyResult] = useState();
  const [dataResources, setResources] = useState([]);
  const [newupdated, setnewupdated] = useState({
    newValue: "",
    confidenceLevel: "high",
    note: "",
    dateOfUpdate: new Date(),
    okrId: "",
    goal: "",
    currentValue: "",
    keyResultId: "",
  });

  useEffect(() => {
    let str = ownerID?.map((itm) => itm.value)?.toString() || '';
    let flt = {
      ownerId: str
    }

    if (user?.role == "employee") {


    } else {
      flt.addedBy = user?.id || user?._id || '';
    }
    getData({ ...flt });
    setfilter({ ...filter, ...flt });
  }, [id]);

  useEffect(() => {
    getSessions();
    getOwners();
    if (id) {
      getSessionDetail();
    }
    if (sessionss?.data) {
      // localStorage.setItem("sessionNAme", sessionss?.data);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      let el = document.getElementById("pills-objective-tab");
      if (el) {
        el.click();
      }
    }
  }, [id, user]);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [childObjective, setChildObjective] = useState(); // Define childObjective state

  const getData = (p = {}, load = true, page = 1) => {
    let filt = {
      ...filter,
      sessionId: id || sessionId || getsessionId,
      keyType: "okr,child_objective",
      sortBy: "createdAt desc",
      page,
      ...p,
    };

    if (user?.role !== "employee") {
      filt = { ...filt, addedBy: user?.id || user?._id };
    } else {
      filt = { ...filt, addedBy: user?.addedBy || user?.addedBy };
    }

    loader(true);
    setLoadingState(true);
    ApiClient.get(`okrs`, filt).then((res) => {
      setLoadingState(false);
      if (res.success) {
        const newData = res?.data;
        if (page === 1) {
          setdata(newData); // Clear existing data if it's the first page
        } else {
          setdata((prevData) => {
            const combinedData = [...prevData, ...newData];
            const uniqueData = combinedData.filter((item, index, self) =>
              index === self.findIndex((t) => t._id === item._id)
            );
            return uniqueData;
          });
        }
        settotal(res?.total);
        setChildObjective(res?.data.childObjective);
        if ((detail?.id || detail?._id) && load) {
          setDetail(
            res?.data?.find(
              (item) => item?._id === detail?.id || item?._id === detail?._id
            )
          );
        }
        if (res?.data.length === 0) {
          setHasMore(false);
        }
      }
      loader(false);
    },);
  };

  useEffect(() => {
    getData({}, true, 1);
  }, []);

  const fetchMoreData = () => {
    setPage((prevPage) => {
      const nextPage = prevPage + 1;
      getData({}, true, nextPage);
      return nextPage;
    });
  };

  const [OKRListing, setOKRListing] = useState([]);

  const getOKRListing = () => {
    loader(true)
    ApiClient.get(`okrs?sessionId=${id}&level=0&page=1&count=10`).then(async (res) => {
      if (res.success) {
        let data = res?.data.map(itm => {
          itm.child = []
          return itm
        })

        let i = 0;
        for await (let item of data) {
          let child = []
          if (item.childExist) {
            const res = await ApiClient.get(`okrs?okrId=${item?.id || item?._id}&level=${item?.level + 1}`)
            if (res.success) {
              child = res.data
            }
          }
          data[i].child = child
          i++
        }
        setOKRListing(data);
      }
      loader(false)
    });
  };


  const getSessionDetail = () => {
    ApiClient.get(`session?id=${id || getsessionId}`).then((res) => {
      if (res.data?.id) {
        setform({
          ...form,
          session: { value: res?.data?.id, label: res?.data?.title },
        });
      }
    });
  };

  const getSessions = () => {
    let payload = {
      isDeleted: false,
    };
    if (user?.role == "employee") {
      payload = {
        ...payload,
        addedBy: user?.addedBy,
        sortBy: "start_date asc",
        // active: true
        // sortBy: localStorage.getItem('CreatedAt') == 'true' ? "start_date asc" : "updatedAt desc",
        // active:localStorage.getItem('CreatedAt') == 'true' ? true :''
      };
    } else {
      payload = {
        isDeleted: false,
        addedBy: user?.id || user?._id,
        sortBy: "start_date asc",
        // active: true
      };
    }
    setLoadingState(true);
    ApiClient.get(`sessions`, payload).then((res) => {
      if (res.success) {
        setResources(res?.data);
        setLoadingState(false);
        setsessions(
          res?.data?.map((item) => {
            return { value: item?._id || item?.id, label: item?.title };
          })
        );
        if (
          !localStorage.getItem("ownerId") &&
          !localStorage.getItem("sessionID")
        ) {
          // getData({ sessionId: res?.data[0]?._id || res?.data[0]?.id });
          // getcounts(res?.data[0]?._id || res?.data[0]?.id)
          // localStorage.setItem("sessionID",res?.data[0]?._id || res?.data[0]?.id)
          // localStorage.setItem("sessionNAme",res?.data[0]?.title)
          // localStorage.setItem("endate",res?.data[0]?.end_date)
        }
      }
    });
  };

  const confidenceLevelClass = (item) => {
    let value = `${item?.confidenceLevel ==
      "low" ||
      item?.confidenceLevel ==
      ""
      ? "red-color"
      : item?.confidenceLevel ==
        "medium"
        ? "orange-color"
        : item?.confidenceLevel ==
          "high"
          ? "green-color"
          : ""
      }`

    if ((item?.keyType == "child_objective" || item?.keyType == "okr")) {
      value = ''
    }

    return value
  }

  const getKeyResults = (level=0) => {
    // loader(true);
    ApiClient.get(`okrs`, {
      sessionId:id,
      level:level,
      // okrId: id ? id : detail?.id ? detail?.id : detail?._id,
      addedBy: user?.role == "employee" ? user?.id || user?._id : ''
    }
    ).then((res) => {
      if (res.success) {
        setkeyResultData(res?.data);
        setparentKeyResult(
          res?.data?.map((item) => {
            return { value: item?._id, label: item?.title };
          })
        );
      }
      // loader(false);
    });
  };

  const clearFields = () => {
    setform({ ...form, title: "", ownerIds: "", whyNow: '', contributors: "" });
  };

  const getOwners = () => {
    ApiClient.get(`users/list?status=active&addedBy=${user?.id || user?._id}`).then((res) => {
      if (res.success) {
        ApiClient.get(`teams?addedBy=${user?.id || user?._id}`).then((res1) => {
          let arr2 = res1?.data?.map((item) => {
            return {
              value: item?._id || item?.id,
              id: item?._id || item?.id,
              label: item?.fullName || item?.firstName,
            };
          });
          console.log(res?.data, "res------------")
          let arr = res?.data?.map((item) => {
            return {
              id: item?.id || item?._id,
              value: item?.id || item?._id,
              label: item?.fullName || item?.firstName,
            };
          });
          let Multiarr2 = res1?.data?.map((item) => {
            return {
              id: item?._id || item?.id,
              name: item?.fullName || item?.firstName,
            };
          });
          let Multiarr = res?.data?.map((item) => {
            return {
              id: item?.id || item?._id,
              name: item?.fullName || item?.firstName,
            };
          });

          setmultiOptions([...Multiarr, ...Multiarr2]);
          setowners([...arr, ...arr2]);
        });
      }
    });
  };

  const handleAction = (type, id) => {
    if (type == "Edit") {
      if(!id) return
      ApiClient.get(`okr?id=${id}`).then((res) => {
        if (res.data?.id) {
          let parentData=res.data.parentData||null
          setdetail(res?.data);
          setform({
            id: res?.data?.id,
            title: res?.data?.title,
            session: {
              value: res?.data?.sessionTitle?.id,
              label: res?.data?.sessionTitle?.title,
            },
            ownerIds: res?.data?.ownerIds,
            parentId: res.data?.parentId || null,
            parent:parentData?{value:res.data?.parentId,label:<div className='d-flex'>
              <span className="mr-2 objective-img">
                  {/* <LuCircleDotDashed /> */}
                  {parentData?.keyType == "child_objective" ||
                      parentData?.keyType == "okr" ? (
                      <span className="objective-img ">
                          <svg
                              stroke="currentColor"
                              fill="none"
                              stroke-width="2"
                              viewBox="0 0 24 24"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                              <path d="M10.1 2.18a9.93 9.93 0 0 1 3.8 0"></path>
                              <path d="M17.6 3.71a9.95 9.95 0 0 1 2.69 2.7"></path>
                              <path d="M21.82 10.1a9.93 9.93 0 0 1 0 3.8"></path>
                              <path d="M20.29 17.6a9.95 9.95 0 0 1-2.7 2.69"></path>
                              <path d="M13.9 21.82a9.94 9.94 0 0 1-3.8 0"></path>
                              <path d="M6.4 20.29a9.95 9.95 0 0 1-2.69-2.7"></path>
                              <path d="M2.18 13.9a9.93 9.93 0 0 1 0-3.8"></path>
                              <path d="M3.71 6.4a9.95 9.95 0 0 1 2.7-2.69"></path>
                              <circle cx="12" cy="12" r="1"></circle>
                          </svg>
                      </span>
                  ) : (
                      <span className="key-img">
                          <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                              <path fill="none" d="M0 0h24v24H0V0z"></path>
                              <path d="M17 4h3v16h-3V4zM5 14h3v6H5v-6zm6-5h3v11h-3V9z"></path>
                          </svg>
                      </span>
                  )}
              </span>
              <span>{parentData.title}</span></div>}:null,
                keyType:res.data.keyType,
            contributors: res?.data?.contributors,
            whyNow: res?.data?.whyNow,
          });
          console.log("parentData",parentData)
          setchlidObjective(parentData)
          document.getElementById("CreateOKRModalOpen").click();
        }
      });
    } else if (type == "Child Objective") {
      let id1=id?.id || id?._id
      if(!id1) return
      ApiClient.get(`okr?id=${id1}`).then((res) => {
        if (res.data?.id) {
          setdetail(res?.data);
          let parentData=res.data.parentData||null
          let payload={
            id: res?.data?.id,
            title: res?.data?.title,
            session: {
              value: res?.data?.sessionTitle?.id,
              label: res?.data?.sessionTitle?.title,
            },
            keyType:res.data.keyType,
            parentId: res.data.parentId || null,
            ownerIds: res?.data?.ownerIds,
            contributors: res?.data?.contributors,
            whyNow: res?.data?.whyNow,
          }
          setform(payload);
          console.log("payload",payload)
          setchlidObjective(parentData)
          getKeyResults((res?.data.level-1))
          document.getElementById("CreateOKRModalOpen").click();
        }
      })
    }
    else {
      if (window.confirm("Do you really want to delete this okr")) {
        ApiClient.delete(`okr?id=${id}`).then((res) => {
          if (res.success) {
            toast.success(res?.message);
            getData();
            getOKRListing()
            document.getElementById("CloseFullSideScreenModal").click();
          }
        });
      }
    }
  };

  const detailModal = (ids, value = true) => {
    let id = ids || ''
    console.log("detailModal")
    if (!layout) {
      return;
    }
    loader(true)
    ApiClient.get(`okr?id=${id}`).then((res) => {
      loader(false)
      if (res?.data?.id) {
        setdetail(res?.data);
        // setNewValue(updateKeyResult);
        setupdateKeyResult({
          ...updateKeyResult,
          okrId: res?.data?.id || res?.data?._id,
        });
        setnewupdated({
          ...updateKeyResult,
          okrId: res?.data?.id || res?.data?._id,
        });


        if (value && !chlildObjective) {
          document.getElementById("OpenFullSideScreenModal").click();
        }
        setchlidObjective("");
        setform({ ...form, parentId: null, whyNow: '', contributors: null })
      }
    });
  };


const findOKRProgress = (data) => {
    return methodModel.findOKRProgress(data)
};


  const clearOKRForm = () => {
    setdetail("");
    setchlidObjective("");
    setform({ ...form,id:'', ownerIds: "",title: "",keyType:'okr', parentId: null, whyNow: '', contributors: null })
  };

  const resources =
    dataResources &&
    dataResources.map((item) => {
      return { id: item?._id, title: item?.title };
    });

  const handleFilter = () => {
    if (filter?.ownerId && filter?.ownerId?.length > 0) {
      let arr = filter?.ownerId || ownerID;
      getData({ page: 1, ownerId: arr.map((item) => item?.value).toString() });
    } else {
      getData({ page: 1, ownerId: "" });
    }
  };

  const handlechangesessions = (e) => {
    if (e) {
      dispatch(sessions(`${e.label}`));
      setSessionIdFilter(e);
      setfilter({ ...filter, sessionId: e });
      navigate.push(`/sessions/okrs/${e.value}`);
      // localStorage.setItem("sessionID", e.value);
    }
  };


 const ConfidenceLevel = ({ data }) => {
    return <>
      <div className="confidence-level text-end">
        <span
          className={`p-2 badges-color ${data?.confidenceLevel ==
            "low" ||
            data?.confidenceLevel ==
            ""
            ? "red-color"
            : data?.confidenceLevel ==
              "medium"
              ? "orange-color"
              : data?.confidenceLevel ==
                "high"
                ? "green-color"
                : ""
            } text-capitalize`}
        >
          {data?.confidenceLevel ==
            "low" ? (
            <span className="badges-diff">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                class="me-1"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2.5 12a9.5 9.5 0 1 1 19 0 .75.75 0 0 0 1.5 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11a.75.75 0 0 0 0-1.5A9.5 9.5 0 0 1 2.5 12Z"></path>
                <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2.5 2.75a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm3.75 2.75a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Z"></path>
              </svg>
              OFF
              TRACK{" "}
            </span>
          ) : data?.confidenceLevel ==
            "medium" ? (
            <span className="badges-diff">
              <svg
                stroke="currentColor"
                fill="none"
                stroke-width="0"
                viewBox="0 0 24 24"
                class="me-1"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z"
                  fill="currentColor"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
                  fill="currentColor"
                ></path>
              </svg>
              AT
              RISK
            </span>
          ) : data?.confidenceLevel ==
            "high" ? (
            <span className="badges-diff">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                class="me-1"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 2.5a9.5 9.5 0 1 0 0 19 .75.75 0 0 1 0 1.5C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11a.75.75 0 0 1-1.5 0A9.5 9.5 0 0 0 12 2.5Z"></path>
                <path d="m13.759 17.48 3.728 3.314a.308.308 0 0 0 .513-.23V18h4.25a.75.75 0 0 0 0-1.5H18v-2.564a.308.308 0 0 0-.513-.23L13.76 17.02a.308.308 0 0 0 0 .46ZM12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
              </svg>
              ON
              TRACK
            </span>
          ) : (
            <span className="badges-diff">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                class="me-1"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2.5 12a9.5 9.5 0 1 1 19 0 .75.75 0 0 0 1.5 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11a.75.75 0 0 0 0-1.5A9.5 9.5 0 0 1 2.5 12Z"></path>
                <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2.5 2.75a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm3.75 2.75a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Z"></path>
              </svg>
              OFF
              TRACK
            </span>
          )}
        </span>
      </div>
    </>
  }


  const handleKeyResultAction = (type, id) => {
    if (type == "Delete") {
      if (window.confirm(`Do you really want to delete this key result`)) {
        ApiClient.delete(`okr?id=${id}`).then((res) => {
          if (res.success) {
            getData({}, false);
            getOKRListing();
            if (detail?.id || detail?._id) detailModal(detail?.id || detail?._id, false);
          }
        });
      }
    } else if (type == "Child Objective") {
      setchlidObjective(id);
      setform({ ...form,id:'', parentId: id?._id || id?.id, whyNow: '', contributors: '', ownerIds: "",title: "",keyType:'child_objective' });
      document.getElementById("CreateOKRModalOpen").click();
    } else if (type == "Child Key Result") {
      document.getElementById("OpenKeyResultModal").click();
      setchildKeyResult(id?._id || id?.id);
    } else {
      setkeyResult({
        title: "",
        unit: "",
        targetNumber: "",
        initialNumber: "",
        description: "",
        ownerIds: [],
        deadline: "no custom deadline",
        softDeadline: "",
        hardDeadline: "",
        session: null,
        ...id,
        ownerIds: id?.ownerDetails?.map((item) => {
          return { value: item?.id, label: item?.name };
        }),
        // softDeadline: new Date(id?.softDeadline),
        // hardDeadline: new Date(id?.hardDeadline),
      });
      document.getElementById("OpenKeyResultModal").click();
    }
  };


  const keyResultPercent=(item)=>{
    return methodModel.keyResultPercent(item)
}

const handleUpdateKeyResultModal = (item) => {
  setupdateKeyResult({
    ...updateKeyResult,
    goal: item?.targetNumber,
    currentValue: item?.initialNumber,
    parentId:item.parentId||null,
    newValue: item?.newValue || item?.initialNumber,
    keyResultId: item?._id || item?.id,
    confidenceLevel: item?.confidenceLevel,
    note: item?.description,
    dateOfUpdate: new Date(item?.dateOfUpdate || new Date()),
    unit: item?.unit,
  });
};

const [valueData, setNewValue] = useState("");
const [valueDataUnit, setNewValueUnit] = useState("");


  return (
    <>
      <div className="inners_wrapper ">
        {data?.length >= 0 || filter?.ownerId ? (
          <>
            <div className="tbs_data_okrs">
              <div className="okrs_objec_inners mt-3">
                {layout && (
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center ">
                      <Tooltip placement="right" title="Back">
                        <span
                          className="backarrow"
                          onClick={(e) => {
                            navigate.push(`/sessions`);
                            dispatch(sessions(""));
                          }}
                        >
                          <a className="aclass">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              data-icon="goal"
                              aria-hidden="true"
                              viewBox="0 0 448 512"
                              title="back"
                            >
                              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                            </svg>
                          </a>
                        </span>
                      </Tooltip>
                      <ul
                        class="nav nav-pills okrs_tabsul ms-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li class="nav-item w-50" role="presentation">
                          <button
                            class="nav-link active"
                            id="pills-objective-tab"
                            data-toggle="pill"
                            data-target="#pills-objective"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                          >
                            <span className="mr-2">
                              <MdDataObject />
                            </span>
                            Objectives
                          </button>
                        </li>
                        <li class="nav-item w-50" role="presentation">
                          <button
                            class="nav-link"
                            id="pills-allignment-tab"
                            data-toggle="pill"
                            data-target="#pills-allignment"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          // onClick={() => getData()}
                          >
                            <span className="mr-2">
                              <CiAlignRight />
                            </span>{" "}
                            Alignment
                          </button>
                        </li>
                      </ul>

                      <div className="dropdown filter-dropdown ms-3">
                        <Menu>
                          <Menu.Button
                            className="btn border-btn d-flex align-items-center"
                            onClick={(e) =>
                              setSessionIdFilter({
                                ...SessionIdFilter,
                                label: sessionss?.data,
                                value: id,
                              })
                            }
                          >
                            <CiFilter className="me-2" />
                            Filters
                            <IoIosArrowDown className="ms-2" />
                          </Menu.Button>

                          <Menu.Items>
                            <div
                              className="dropdown-menu d-block"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                className="dropdown-item "
                                onChange={(e) => e.stopPropagation()}
                              >
                                {/* <div className="d-flex mb-3 mt-3 align-items-center">
                                <label>Session</label>
                                <div className="ms-2 w-100">
                                  <input
                                    type="text"
                                    name="text"
                                    disabled
                                    value={filter?.search|| sessions?.data}
                                    onChange={(e) =>
                                      setfilter({
                                        ...filter,
                                        search: e.target.value,
                                      })
                                    }
                                    className="form-control "
                                  />
                                </div>
                              </div> */}
                                <div className="d-flex  align-items-center">
                                  <label>Session</label>
                                  <div
                                    className="ms-2 w-100"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    {LoadingState ? null : (
                                      <Select
                                        value={SessionIdFilter}
                                        isClearable={true}
                                        onChange={(e) =>
                                          handlechangesessions(e)
                                        }
                                        options={session}
                                        className="basic-multi-select text-capitalize"
                                        placeholder="Select Owners"
                                      />
                                    )}
                                  </div>
                                </div>
                              </a>
                              {user?.role != "employee" && <a
                                className="dropdown-item  mb-3"
                                onChange={(e) => e.stopPropagation()}
                              >
                                <div className="d-flex  align-items-center">
                                  <label>Owners</label>
                                  <div
                                    className="ms-2 w-100"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <Select
                                      value={filter?.ownerId}
                                      isClearable={true}
                                      onChange={(e) => {
                                        setfilter({ ...filter, ownerId: e });
                                        localStorage.setItem(
                                          "ownerId",
                                          JSON.stringify(e)
                                        );
                                      }}
                                      options={owners}
                                      className="basic-multi-select text-capitalize"
                                      placeholder="Select Owners"
                                      isMulti
                                    />
                                  </div>
                                </div>
                              </a>}
                              <div className="clear-btn me-3 mb-2 d-flex align-items-center justify-content-end">
                                {(filter?.search ||
                                  filter?.ownerId ||
                                  filter?.ownerId?.length != 0) && (
                                    <>
                                      <button
                                        className="btn btn-primary me-2 filter-btn d-flex align-items-center"
                                        onClick={(e) => handleFilter()}
                                      >
                                        Filter
                                      </button>
                                      <button
                                        className="btn btn-secondary filter-btn d-flex align-items-center"
                                        onClick={(e) => {
                                          setfilter({
                                            ...filter,
                                            page: 1,
                                            search: "",
                                            ownerId: "",
                                          });
                                          getData({
                                            page: 1,
                                            search: "",
                                            ownerId: "",
                                          });
                                          localStorage.removeItem("ownerId");
                                          // localStorage.removeItem("sessionID");
                                          // localStorage.setItem(
                                          //   "ownerId",
                                          //   JSON.stringify([])
                                          // );
                                        }}
                                      >
                                        <RxCross2 className="me-2" />
                                        Clear
                                      </button>
                                    </>
                                  )}
                              </div>
                            </div>
                          </Menu.Items>
                        </Menu>
                      </div>
                    </div>

                    {user?.role != "employee" && <div className="text-right ">
                      <button
                        className="btn btn-primary"
                        onClick={(e) => clearOKRForm()}
                        id="OpenCreateOKRModal"
                        data-toggle="modal"
                        data-target="#OKRsModal"
                      >
                        Create OKRs
                      </button>
                    </div>}
                  </div>
                )}
                
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-objective"
                    role="tabpanel"
                    aria-labelledby="pills-objective-tab"
                  >
                    <div className="objective_tables">
                      {!loading ? (
                        <InfiniteScroll
                          dataLength={data.length}
                          next={fetchMoreData}
                          hasMore={hasMore}
                          height={650}
                          endMessage={
                            <p style={{ textAlign: 'center' }}>
                              <b>Yay! You have seen it all</b>
                            </p>
                          }
                        >
                        <div className="mvp_table  objective-tbl-main mt-3 obj-tbl">
                          <div className=" table-responsive">
                            <table className="table table-hover ">
                              <thead>
                                <tr>
                                  <th scope="col">
                                    {" "}
                                    <span>Objective name</span>
                                  </th>
                                  <th scope="col">
                                    {" "}
                                    <span className="paddingleft2 d-block">
                                      Progress
                                    </span>
                                  </th>
                                  <th scope="col">
                                    <span className="d-block">
                                      {" "}
                                      Key Results , Child Objectives and
                                      Confidence
                                    </span>
                                  </th>
                                  {/* <th scope="col">
                                    <span className="d-block">
                                      Owners
                                    </span>
                                  </th>
                                  <th scope="col">
                                    <span className="d-block">
                                      Contributors
                                    </span>
                                  </th> */}
                                  {/* <th scope="col">Confidence</th> */}
                                  {/* <th scope="col">Owner</th> */}
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>

                              {loading ? null : (
                                <>
                                  <tbody>
                                    {data &&
                                      data?.map((item) => {
                                        return (
                                          <tr key={item._id || item.id}>
                                            <td className="objective-name">
                                              <span
                                                className="fs-5 pointer d-flex mb-0 w-100"
                                                onClick={item?.keyType == "child_objective" ? (e) => detailModal(item?._id
                                                  ? item._id
                                                  : item.id) : (e) =>
                                                  detailModal(
                                                    item?._id
                                                      ? item._id
                                                      : item.id
                                                  )
                                                }
                                              >
                                                <Tooltip
                                                  title="Objective"
                                                  placement="top"
                                                >
                                                  <span className="mr-1 objective-img">
                                                    <LuCircleDotDashed />
                                                  </span>
                                                </Tooltip>

                                                <span className="tdoksnames text-capitalize">
                                                  {item?.title}
                                                </span>
                                              </span>
                                              <div className=" owners-number pleft owner-no-main">
                                                {item?.ownerDetails?.map(
                                                  (itm, i) => {
                                                    return (
                                                      <>
                                                        {
                                                          itm?.id &&
                                                          i < 4 && (
                                                            <>
                                                              <Tooltip
                                                                title={
                                                                  itm?.name
                                                                }
                                                                placement="top"
                                                              >
                                                                <span className="pointer">
                                                                  <svg
                                                                    stroke="currentColor"
                                                                    fill="none"
                                                                    stroke-width="1.5"
                                                                    viewBox="0 0 24 24"
                                                                    aria-hidden="true"
                                                                    height="1em"
                                                                    width="1em"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                  >
                                                                    <path
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                      d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                                                                    ></path>
                                                                  </svg>
                                                                </span>
                                                              </Tooltip>
                                                            </>
                                                          )
                                                          // : (
                                                          //   <Tooltip
                                                          //     title={"No Owner"}
                                                          //     placement="top"
                                                          //   >
                                                          //     <span className="pointer ">
                                                          //       <FaUsersSlash />
                                                          //     </span>
                                                          //   </Tooltip>
                                                          // )
                                                        }
                                                      </>
                                                    );
                                                  }
                                                )}
                                                {item?.ownerDetails?.length >
                                                  4 && (
                                                    <small className="pt-1">
                                                      +more
                                                    </small>
                                                  )}
                                              </div>
                                              {/* <span className="ml-3 pt-2 text-capitalize">{item?.parentData?.title || ""}</span> */}
                                            </td>
                                            <td className="">
                                              <div className="paddingleft2">
                                                <Tooltip
                                                  title="Objective Progress"
                                                  placement="top"
                                                >
                                                  <span className={`progressDiv  progress-color`}>{findOKRProgress(item?.keyResults, 'this okr is from okr listing')}</span>
                                                </Tooltip>
                                              </div>
                                            </td>

                                            <td>
                                              <div className="key-area">
                                                {item?.keyResults?.length > 0
                                                  ? item?.keyResults?.map(
                                                    (data) => {
                                                      return (
                                                        <div className="key-result-main">
                                                          <div className="d-flex gap-4  margnit align-items-center">
                                                            <div className="widthflex">
                                                              <label className="text-capitalize mb-0  key-result-table d-flex ">
                                                                <Tooltip
                                                                  title="Key Result"
                                                                  placement="top"
                                                                >
                                                                  <span className="mr-1 key-img ">
                                                                    <MdOutlineSignalCellularAlt />
                                                                  </span>
                                                                </Tooltip>
                                                                {
                                                                  data?.title
                                                                }
                                                              </label>
                                                            </div>

                                                            {/* <div className="col-md-3 text-end">
                                                              {data?.confidenceLevel ? (
                                                                <span
                                                                  className={`p-2 radius-sm badge-tbl   btn-size-5 ${
                                                                    data?.confidenceLevel ==
                                                                    "low"
                                                                      ? "yello-btn"
                                                                      : data?.confidenceLevel ==
                                                                        "medium"
                                                                      ? "btn-primary"
                                                                      : "btn-success"
                                                                  } text-capitalize`}
                                                                >
                                                                  {
                                                                    data?.confidenceLevel
                                                                  }
                                                                </span>
                                                              ) : null}
                                                            </div> */}

                                                            <span className={`progressDiv mx-2 progress-width ${confidenceLevelClass(data)}`}> {keyResultPercent(data)+ "%"}</span>


                                                            <div className="copydiv d-flex gap-2 align-items-center">
                                                              <div className="key-result-main border-0">
                                                                <div className="row pb-0">
                                                                  <div className="col-lg-12">
                                                                    <ConfidenceLevel data={data} />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="owners-number d-flex  flex-wrap gap-2">
                                                                {data?.krOwners?.map(
                                                                  (itm, i) => {
                                                                    if (i < 4)
                                                                      return (
                                                                        <>
                                                                          {itm?.id ? (
                                                                            <span  style={{
                                                                              backgroundColor:itm.color||''
                                                                            }}>{itm?.name}</span>
                                                                          ) : (
                                                                            <span className="fs-6">
                                                                              <Tooltip
                                                                                title="No owner"
                                                                                placement="top"
                                                                              >
                                                                                <svg
                                                                                  stroke="currentColor"
                                                                                  fill="currentColor"
                                                                                  stroke-width="0"
                                                                                  viewBox="0 0 640 512"
                                                                                  height="1em"
                                                                                  width="1em"
                                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                                >
                                                                                  <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L440.6 320H618.7c11.8 0 21.3-9.6 21.3-21.3C640 239.8 592.2 192 533.3 192H490.7c-15.9 0-31 3.5-44.6 9.7c1.3 7.2 1.9 14.7 1.9 22.3c0 30.2-10.5 58-28 79.9l-25.2-19.7C408.1 267.7 416 246.8 416 224c0-53-43-96-96-96c-31.1 0-58.7 14.8-76.3 37.7l-40.6-31.8c13-14.2 20.9-33.1 20.9-53.9c0-44.2-35.8-80-80-80C116.3 0 91.9 14.1 77.5 35.5L38.8 5.1zM106.7 192C47.8 192 0 239.8 0 298.7C0 310.4 9.6 320 21.3 320H234.7c.2 0 .4 0 .7 0c-20.6-18.2-35.2-42.8-40.8-70.8L121.8 192H106.7zM261.3 352C187.7 352 128 411.7 128 485.3c0 14.7 11.9 26.7 26.7 26.7H485.3c10.5 0 19.5-6 23.9-14.8L324.9 352H261.3zM512 160A80 80 0 1 0 512 0a80 80 0 1 0 0 160z"></path>
                                                                                </svg>
                                                                              </Tooltip>
                                                                            </span>
                                                                          )}
                                                                        </>
                                                                      );
                                                                  }
                                                                )}
                                                              </div>
                                                            </div>

                                                          </div>
                                                        </div>
                                                      );
                                                    }
                                                  )
                                                  : "--"}
                                              </div>
                                            </td>

                                            {/* <td>
                                              <div className="owners-number d-flex justify-content-end flex-wrap gap-2">
                                                {item?.ownerDetails?.map(
                                                  (itm, i) => {
                                                    if (i < 4)
                                                      return (
                                                        <>
                                                          {itm?.id ? (
                                                            <span>{itm?.name}</span>
                                                          ) : (
                                                            <span className="fs-6">
                                                              <Tooltip
                                                                title="No owner"
                                                                placement="top"
                                                              >
                                                                <svg
                                                                  stroke="currentColor"
                                                                  fill="currentColor"
                                                                  stroke-width="0"
                                                                  viewBox="0 0 640 512"
                                                                  height="1em"
                                                                  width="1em"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L440.6 320H618.7c11.8 0 21.3-9.6 21.3-21.3C640 239.8 592.2 192 533.3 192H490.7c-15.9 0-31 3.5-44.6 9.7c1.3 7.2 1.9 14.7 1.9 22.3c0 30.2-10.5 58-28 79.9l-25.2-19.7C408.1 267.7 416 246.8 416 224c0-53-43-96-96-96c-31.1 0-58.7 14.8-76.3 37.7l-40.6-31.8c13-14.2 20.9-33.1 20.9-53.9c0-44.2-35.8-80-80-80C116.3 0 91.9 14.1 77.5 35.5L38.8 5.1zM106.7 192C47.8 192 0 239.8 0 298.7C0 310.4 9.6 320 21.3 320H234.7c.2 0 .4 0 .7 0c-20.6-18.2-35.2-42.8-40.8-70.8L121.8 192H106.7zM261.3 352C187.7 352 128 411.7 128 485.3c0 14.7 11.9 26.7 26.7 26.7H485.3c10.5 0 19.5-6 23.9-14.8L324.9 352H261.3zM512 160A80 80 0 1 0 512 0a80 80 0 1 0 0 160z"></path>
                                                                </svg>
                                                              </Tooltip>
                                                            </span>
                                                          )}
                                                        </>
                                                      );
                                                  }
                                                )}
                                              </div>
                                            </td> */}
                                            {/* <td>
                                              <div className="owners-number d-flex justify-content-end flex-wrap gap-2">
                                                {item?.contributorsDetails?.map(
                                                  (itm, i) => {
                                                    if (i < 4)
                                                      return (
                                                        <>
                                                          {itm?.id ? (
                                                            <span>{itm?.name}</span>
                                                          ) : (
                                                            <span className="fs-6">
                                                              <Tooltip
                                                                title="No owner"
                                                                placement="top"
                                                              >
                                                                <svg
                                                                  stroke="currentColor"
                                                                  fill="currentColor"
                                                                  stroke-width="0"
                                                                  viewBox="0 0 640 512"
                                                                  height="1em"
                                                                  width="1em"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L440.6 320H618.7c11.8 0 21.3-9.6 21.3-21.3C640 239.8 592.2 192 533.3 192H490.7c-15.9 0-31 3.5-44.6 9.7c1.3 7.2 1.9 14.7 1.9 22.3c0 30.2-10.5 58-28 79.9l-25.2-19.7C408.1 267.7 416 246.8 416 224c0-53-43-96-96-96c-31.1 0-58.7 14.8-76.3 37.7l-40.6-31.8c13-14.2 20.9-33.1 20.9-53.9c0-44.2-35.8-80-80-80C116.3 0 91.9 14.1 77.5 35.5L38.8 5.1zM106.7 192C47.8 192 0 239.8 0 298.7C0 310.4 9.6 320 21.3 320H234.7c.2 0 .4 0 .7 0c-20.6-18.2-35.2-42.8-40.8-70.8L121.8 192H106.7zM261.3 352C187.7 352 128 411.7 128 485.3c0 14.7 11.9 26.7 26.7 26.7H485.3c10.5 0 19.5-6 23.9-14.8L324.9 352H261.3zM512 160A80 80 0 1 0 512 0a80 80 0 1 0 0 160z"></path>
                                                                </svg>
                                                              </Tooltip>
                                                            </span>
                                                          )}
                                                        </>
                                                      );
                                                  }
                                                )}
                                              </div>
                                            </td> */}

                                            {/* <td className="">
                                              {item?.keyResults?.length > 0
                                                ? item?.keyResults.map(
                                                  (itm) => {
                                                    return (
                                                      <>
                                                        <div className="key-result-main border-0">
                                                          <div className="row ">
                                                            <div className="col-lg-12">
                                                              <div className="confidence-level">
                                                                <span
                                                                  className={`p-2 badges-color ${itm?.confidenceLevel ==
                                                                    "low" ||
                                                                    itm?.confidenceLevel ==
                                                                    ""
                                                                    ? "red-color"
                                                                    : itm?.confidenceLevel ==
                                                                      "medium"
                                                                      ? "orange-color"
                                                                      : itm?.confidenceLevel ==
                                                                        "high"
                                                                        ? "green-color"
                                                                        : ""
                                                                    } text-capitalize`}
                                                                >
                                                                  {itm?.confidenceLevel ==
                                                                    "low" ? (
                                                                    <span className="badges-diff">
                                                                      <svg
                                                                        stroke="currentColor"
                                                                        fill="currentColor"
                                                                        stroke-width="0"
                                                                        viewBox="0 0 24 24"
                                                                        class="me-1"
                                                                        height="1em"
                                                                        width="1em"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                      >
                                                                        <path d="M2.5 12a9.5 9.5 0 1 1 19 0 .75.75 0 0 0 1.5 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11a.75.75 0 0 0 0-1.5A9.5 9.5 0 0 1 2.5 12Z"></path>
                                                                        <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2.5 2.75a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm3.75 2.75a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Z"></path>
                                                                      </svg>
                                                                      OFF TRACK{" "}
                                                                    </span>
                                                                  ) : itm?.confidenceLevel ==
                                                                    "medium" ? (
                                                                    <span className="badges-diff">
                                                                      <svg
                                                                        stroke="currentColor"
                                                                        fill="none"
                                                                        stroke-width="0"
                                                                        viewBox="0 0 24 24"
                                                                        class="me-1"
                                                                        height="1em"
                                                                        width="1em"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                      >
                                                                        <path
                                                                          d="M12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z"
                                                                          fill="currentColor"
                                                                        ></path>
                                                                        <path
                                                                          d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z"
                                                                          fill="currentColor"
                                                                        ></path>
                                                                        <path
                                                                          fill-rule="evenodd"
                                                                          clip-rule="evenodd"
                                                                          d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
                                                                          fill="currentColor"
                                                                        ></path>
                                                                      </svg>
                                                                      AT RISK
                                                                    </span>
                                                                  ) : itm?.confidenceLevel ==
                                                                    "high" ? (
                                                                    <span className="badges-diff">
                                                                      <svg
                                                                        stroke="currentColor"
                                                                        fill="currentColor"
                                                                        stroke-width="0"
                                                                        viewBox="0 0 24 24"
                                                                        class="me-1"
                                                                        height="1em"
                                                                        width="1em"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                      >
                                                                        <path d="M12 2.5a9.5 9.5 0 1 0 0 19 .75.75 0 0 1 0 1.5C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11a.75.75 0 0 1-1.5 0A9.5 9.5 0 0 0 12 2.5Z"></path>
                                                                        <path d="m13.759 17.48 3.728 3.314a.308.308 0 0 0 .513-.23V18h4.25a.75.75 0 0 0 0-1.5H18v-2.564a.308.308 0 0 0-.513-.23L13.76 17.02a.308.308 0 0 0 0 .46ZM12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
                                                                      </svg>
                                                                      ON TRACK
                                                                    </span>
                                                                  ) : (
                                                                    <span className="badges-diff">
                                                                      <svg
                                                                        stroke="currentColor"
                                                                        fill="currentColor"
                                                                        stroke-width="0"
                                                                        viewBox="0 0 24 24"
                                                                        class="me-1"
                                                                        height="1em"
                                                                        width="1em"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                      >
                                                                        <path d="M2.5 12a9.5 9.5 0 1 1 19 0 .75.75 0 0 0 1.5 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11a.75.75 0 0 0 0-1.5A9.5 9.5 0 0 1 2.5 12Z"></path>
                                                                        <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2.5 2.75a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm3.75 2.75a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Z"></path>
                                                                      </svg>
                                                                      OFF TRACK
                                                                    </span>
                                                                  )}
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                    );
                                                  }
                                                )
                                                : null}
                                            </td> */}
                                            {/* <td>
                                              <>
                                                {item?.keyResults?.length > 0
                                                  ? item?.keyResults.map(
                                                      (own) => {
                                                        return (
                                                          <div className="key-result-main border-0">
                                                            <div className="row ">
                                                              <div className="col-lg-12">
                                                                <div className="fontit">
                                                                  {own?.krOwners
                                                                    ? own?.krOwners.map(
                                                                        (
                                                                          itmm
                                                                        ) =>
                                                                          itmm?.name
                                                                      )
                                                                    : ""}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )
                                                  : ""}
                                              </>
                                              <div className="key-result-main border-0">
                                                <div className="row ">
                                                  <div className="col-lg-12">
                                                    <div>
                                                 
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                           
                                            </td> */}
                                            {user?.role == "employee" && <td></td>}
                                            {/* {(item?.keyType != "okr" && user?.role != "employee")  && <td></td>} */}
                                            {(user?.role != "employee") && <td>
                                              {layout && (
                                                <div class="dropdown">
                                                  <span
                                                    class=" text-dark "
                                                    type="button"
                                                    data-toggle="dropdown"
                                                    aria-expanded="false"
                                                  >
                                                    <i
                                                      class="fa fa-ellipsis-v text-dark"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </span>
                                                  <div class="dropdown-menu">
                                                    {item?.keyType == "child_objective" ? <> <span
                                                      className="  dropdown-item pointer"
                                                      onClick={(e) =>
                                                        handleAction(
                                                          "Child Objective",
                                                          item
                                                        )
                                                      }
                                                    >
                                                      {" "}
                                                      <i
                                                        class="fa fa-pen text-dark me-2"
                                                        aria-hidden="true"
                                                      ></i>
                                                      Edit
                                                    </span></> : <>
                                                      <span
                                                        className="  dropdown-item pointer"
                                                        onClick={(e) =>
                                                          handleAction(
                                                            "Edit",
                                                            item?._id || item?.id
                                                          )
                                                        }
                                                      >
                                                        {" "}
                                                        <i
                                                          class="fa fa-pen text-dark me-2"
                                                          aria-hidden="true"
                                                        ></i>
                                                        Edit
                                                      </span></>
                                                    }

                                                    <span
                                                      className="dropdown-item text-danger  pointer"
                                                      onClick={(e) =>
                                                        handleAction(
                                                          "Delete",
                                                          item?._id
                                                        )
                                                      }
                                                    >
                                                      <i
                                                        class="fa fa-trash  text-dark me-2"
                                                        aria-hidden="true"
                                                      ></i>
                                                      Delete
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                            </td>}
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </>
                              )}
                            </table>
                          </div>
                        </div>
                      </InfiniteScroll>
                      ) : null}
                      {!loading && data?.length <= 0 && (
                        <div className="py-3 text-center no-data">
                          <img src="/assets/img/no-data-logo.svg" />
                          No Data Found
                        </div>
                      )}
                      {loading ? (
                        <div className="d-flex justify-content-center py-4">
                          <img
                            src="/assets/img/loader.gif"
                            className="text-center"
                            width="40px"
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-allignment"
                    role="tabpanel"
                    aria-labelledby="pills-allignment-tab"
                  >
                    {layout ? (
                      <>
                        <AlignmentOKR
                        valueData={valueData} setNewValue={setNewValue}
                        valueDataUnit={valueDataUnit} setNewValueUnit={setNewValueUnit}
                          handleKeyResultAction={handleKeyResultAction}
                          handleAction={handleAction}
                          handleUpdateKeyResultModal={handleUpdateKeyResultModal}
                          id={id}
                          ConfidenceLevel={ConfidenceLevel}
                          findOKRProgress={findOKRProgress}
                          updateKeyResult={updateKeyResult}
                          seteditor={seteditor}
                          editor={editor}
                          keyResult={keyResult}
                          setkeyResult={setkeyResult}
                          setupdateKeyResult={setupdateKeyResult}
                          clearFields={clearFields}
                          getData={getData}
                          OKRListing={OKRListing}
                          setOKRListing={setOKRListing}
                          getOKRListing={getOKRListing}
                          data={data}
                          owners={owners}
                          getKeyResults={getKeyResults}
                          detail={detail}
                          detailModal={detailModal}
                          view={item => {
                            if (item?.keyType == "key_result") return
                            if (item?.keyType == "child_objective") {
                              detailModal(item?._id
                                ? item._id
                                : item.id)
                            } else {
                              detailModal(
                                item?._id
                                  ? item._id
                                  : item.id
                              )
                            }
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Tooltip placement="right" title="Back">
              <span
                className="backarrow"
                onClick={(e) => {
                  navigate.push(`/sessions`);
                }}
              >
                <a className="aclass">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    data-icon="goal"
                    aria-hidden="true"
                    viewBox="0 0 448 512"
                    title="back"
                  >
                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                  </svg>
                </a>
              </span>
            </Tooltip>
            {/* <div className="success-story">
              <div className="text-center">
                <p className="text-center">
                  <h3>Start Your Success Story Now</h3>
                </p>
              </div>
              <div className="create_okrs">
              <button
                        className="btn btn-primary"
                        onClick={(e) => clearOKRForm()}
                        id="OpenCreateOKRModal"
                        data-toggle="modal"
                        data-target="#OKRsModal"
                      >
                        Create OKRs
                      </button>
              </div>
            </div> */}
          </>
        )}

        {layout ? (
          <>
            <ModalOKR
            sessionId={id}
            valueData={valueData} setNewValue={setNewValue}
            valueDataUnit={valueDataUnit} setNewValueUnit={setNewValueUnit}
              getOKRListing={getOKRListing}
              detail={detail}
              handleUpdateKeyResultModal={handleUpdateKeyResultModal}
              handleAction={handleAction}
              handleKeyResultAction={handleKeyResultAction}
              findOKRProgress={findOKRProgress}
              keyResult={keyResult}
              setkeyResult={setkeyResult}
              seteditor={seteditor}
              editor={editor}
              owners={owners}
              updateKeyResult={updateKeyResult}
              newupdated={newupdated}
              setupdateKeyResult={setupdateKeyResult}
              clearFields={clearFields}
              chlildObjective={chlildObjective}
              form={form}
              setform={setform}
              getData={getData}
              sessions={session}
              getKeyResults={getKeyResults}
              parentKeyResult={parentKeyResult}
              total={total}
              keyResultData={keyResultData}
              setchlidObjective={setchlidObjective}
              setkeyResultData={setkeyResultData}
              setparentKeyResult={setparentKeyResult}
              detailModal={detailModal}
              childKeyResult={childKeyResult}
              setchildKeyResult={setchildKeyResult}
              result={e => {
                if (e.event == 'Parent Submit' || e.event == 'Chlid Submit') {
                  detailModal(
                    e.value?._id
                      ? e.value?._id
                      : e.value?.id
                  )
                }
              }}
            />
          </>
        ) : (
          <></>
        )}

        {layout ? (
          <>
            <ChildModalOKR
            valueData={valueData} setNewValue={setNewValue}
            valueDataUnit={valueDataUnit} setNewValueUnit={setNewValueUnit}
              detail={detail}
              handleAction={handleAction}
              findOKRProgress={findOKRProgress}
              keyResult={keyResult}
              setkeyResult={setkeyResult}
              seteditor={seteditor}
              editor={editor}
              owners={owners}
              updateKeyResult={updateKeyResult}
              newupdated={newupdated}
              setupdateKeyResult={setupdateKeyResult}
              clearFields={clearFields}
              chlildObjective={chlildObjective}
              form={form}
              setform={setform}
              getData={getData}
              sessions={session}
              getKeyResults={getKeyResults}
              parentKeyResult={parentKeyResult}
              total={total}
              keyResultData={keyResultData}
              setchlidObjective={setchlidObjective}
              setkeyResultData={setkeyResultData}
              setparentKeyResult={setparentKeyResult}
              detailModal={detailModal}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default OKRindex;
